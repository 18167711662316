@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
}

.success-alert {
  background: #44c07c;
  color: #fff;
}
.warning-alert {
  background: #c0b444;
  color: #fff;
}
.error-alert {
  background: #c04444;
  color: #fff;
}

// Estilos do OTP:
.my-super-box-class {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 10px !important;
}

.my-super-class {
  width: 40px !important;
  height: 50px !important;
  margin: 1px !important;
  text-align: center !important;
  font-size: 20px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  transition: border-color 0.3s ease !important;
}

.my-super-filled-class {
  border-color: #8062F7  !important;
}

.my-super-disable-class {
  border-color: gray !important;
}

.my-super-success-class {
  border-color: #44c07c !important;
}

.my-super-error-class {
  border-color: #c04444 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: rgb(0, 150, 136) !important;
}

.mat-step-header {
  pointer-events: none !important;
}
